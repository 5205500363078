<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Penilaian
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Form Penilaian"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <div v-if="position != 'pembimbing'">
            <b-field label="Sistematika dan teknik penulisan sesuai pedoman">
              <b-input type="number" min="0" max="100" v-model="questions.a1" placeholder="" required />
            </b-field>
            <b-field label="Pendahuluan (judul, rumusan masalah, tujuan) dan hipotesis penelitian">
              <b-input type="number" min="0" max="100" v-model="questions.a2" placeholder="" required />
            </b-field>
            <b-field label="Metode dan analisis data">
              <b-input type="number" min="0" max="100" v-model="questions.a3" placeholder="" required />
            </b-field>
            <b-field label="Penarikan kesimpulan">
              <b-input type="number" min="0" max="100" v-model="questions.a4" placeholder="" required />
            </b-field>
            <b-field label="Presentasi hasil penelitian">
              <b-input type="number" min="0" max="100" v-model="questions.a5" placeholder="" required />
            </b-field>
            <b-field label="Diskusi hasil penelitian">
              <b-input type="number" min="0" max="100" v-model="questions.a6" placeholder="" required />
            </b-field>
            <hr>
            <b-field label="Nilai Rata-Rata" class="has-text-centered is-size-2">
              <p>{{ questionPengujiAverage.toFixed(1) }}</p>
            </b-field>
            </div>
            <hr>
            <b-field v-if="position == 'pembimbing'" label="Proses penyelesaian tugas akhir dan sikap ilmiah">
              <b-input type="number" min="0" max="100" v-model="form.nilai_pembimbing" required />
            </b-field>
            <hr>
            <b-field label="Catatan">
                <b-input type="textarea" v-model="catatan"></b-input>
            </b-field>
            <p> Nilai tidak akan bisa diperbaharui setelah disubmit, pastikan untuk mengecek kembali nilai yang akan di berikan.</p>
            <hr>
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <div class="tile is-child"></div>

      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: {},
      questions: {
        a1: null
      },
      data: null,
      catatan: null
    }
  },
  computed: {
    user_id () {
      return this.$store.state.user.user.id
    },
    position () {
      if (this.data) {
        if (parseInt(this.data.ketua_sidang_id) === parseInt(this.user_id)) {
          return 'ketua_sidang'
        }
        if (parseInt(this.data.penguji1_id) === parseInt(this.user_id)) {
          return 'penguji1'
        }
        if (parseInt(this.data.penguji2_id) === parseInt(this.user_id)) {
          return 'penguji2'
        }
        if (parseInt(this.data.pembimbing1_id) === parseInt(this.user_id)) {
          return 'pembimbing'
        }
        if (parseInt(this.data.pembimbing2_id) === parseInt(this.user_id)) {
          return 'pembimbing'
        }
      }

      return null
    },
    titleStack () {
      return ['Dashboard', 'Penilaian']
    },
    questionPengujiAverage () {
      const questionsArr = Object.values(this.questions)
      const average = questionsArr.reduce((acc, cvalue) => parseInt(acc) + parseInt(cvalue)) / questionsArr.length

      return average
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    data (val) {
      if (this.position !== 'pembimbing') {
        this.questions = val[`nilai_${this.position}`] ? val[`nilai_${this.position}`] : {}
      }

      this.form.nilai_pembimbing = val.nilai_pembimbing
    }
  },
  created () {
  },
  methods: {
    getData () {
      this.isLoading = true
      axios
        .get(`/thesis/${this.$route.params.id}`)
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })

      this.$router.push({ name: 'thesis.show', params: { id: this.$route.params.id } })
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form

      if (this.position !== 'pembimbing') {
        this.questions.average = this.questionPengujiAverage
        data[`nilai_${this.position}`] = this.questions
      }

      data[`catatan_${this.position}`] = this.catatan

      this.isLoading = true

      axios
        .post(`/thesis/evaluation/${this.$route.params.id}`, data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>
